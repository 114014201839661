import { defineStore } from 'pinia';
import { useCommonStore } from './commonStore';
import { routeNames } from '@/router';
import { Link } from './models';

export const useNavigationStore = defineStore('navigation', {
    state: () => ({
        activeMenuTab: 0,
        menuItems: {} as any
    }),
    actions: {
        setTabByRoute(route: string) {
            switch (route) {
                case routeNames.messages:
                case routeNames.messageCreate:
                    this.activeMenuTab = 0;
                    break;
                case routeNames.subscriptions:
                case routeNames.subscriptionsEdit:
                case routeNames.subscriptionsCreate:
                    this.activeMenuTab = 1;
                    break;
                case routeNames.driversHandbooks:
                case routeNames.driversHandbooksEdit:
                case routeNames.driversHandbooksCreate:
                    this.activeMenuTab = 2;
                    break;
                case routeNames.accessTokens:
                case routeNames.accessTokensEdit:
                case routeNames.accessTokensCreate:
                    this.activeMenuTab = 3;
                    break;
                case routeNames.areas:
                case routeNames.areasEdit:
                    this.activeMenuTab = 4;
                    break;
                case routeNames.companies:
                case routeNames.companiesEdit:
                    this.activeMenuTab = 5;
                    break;
                case routeNames.mpcStatistics:
                    this.activeMenuTab = 6;
                    break;
            }
        },
        setTabIndex(index: number) {
            this.activeMenuTab = index;
        },
        initMenu() {
            const commonStore = useCommonStore();

            const routeNameList = Object.entries(routeNames).map(item => item[1]);
            const links = commonStore.links.filter(x => x.visibleInMenu).filter(x => routeNameList.some(r => x.rel == r));
            const menuItems = this.createMenuItems(links);

            this.menuItems = menuItems;
        },
        createMenuItems(data: Array<Link>) {
            let links: any = {
                menuStrings: {}
            };
            for (const item of data) {
                links[item.rel] = item.href;
                links.menuStrings[item.rel] = item.rel;
            }
            return links;
        }
    }
});
