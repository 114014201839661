<template>
    <div class="filter-title">{{ `${t('messages.resources.filterTitle')}:` }}</div>
    <div class="filters">
        <v-autocomplete
            v-model="companyModel"
            :items="companiesFilters"
            :label="t('messages.resources.companies')"
            return-object
            variant="outlined"
            data-id="CompanyFilter"
            hide-details
        ></v-autocomplete>
        <v-autocomplete
            v-model="categoryModel"
            :items="categoriesFilters"
            :label="t('messages.resources.filterDeviationType')"
            return-object
            variant="outlined"
            data-id="CategoryFilter"
            hide-details
        ></v-autocomplete>
        <v-autocomplete
            v-model="lineModel"
            :items="linesFilters"
            :label="t('messages.resources.line')"
            return-object
            variant="outlined"
            data-id="LineFilter"
            hide-details
        ></v-autocomplete>
        <v-autocomplete
            v-model="statusModel"
            :items="statusFilters"
            :label="t('messages.resources.listStatus')"
            return-object
            variant="outlined"
            data-id="StatusFilter"
            hide-details
        ></v-autocomplete>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, defineExpose } from 'vue';
import { AutocompleteItem } from '@/shared/models';
import { MessageListFilters, MessageCategoryTemplate } from '../messagesModels';
import { Company } from '@/Features/Companies/companiesModels';
import { Line } from '@/Features/Lines/linesModels';
import { toCompanyAutocompleteItem } from '@/Features/Companies/companyHelpers';
import { toLineAutocompleteItem } from '@/Features/Lines/lineHelpers';
import { MessageStatus } from '../messagesModels';

const { t } = useI18n();

const categoryModel = defineModel<AutocompleteItem<MessageCategoryTemplate | null>>('category');
const companyModel = defineModel<AutocompleteItem<Company>>('company');
const lineModel = defineModel<AutocompleteItem<Line>>('line');
const statusModel = defineModel<AutocompleteItem<string>>('status');

const props = defineProps<{
    companies: Company[];
    categories: MessageCategoryTemplate[];
    lines: Line[];
}>();

const companiesFilters = computed(() => props.companies.map(x => toCompanyAutocompleteItem(x)));
const categoriesFilters = computed(() => {
    const filters = props.categories.map(x => {
        return { title: x.label, value: x.name, data: x };
    });
    return [{ title: t(`messages.resources.filterAll`), value: 'All', data: null }, ...filters];
});
const linesFilters = computed(() => props.lines.map(x => toLineAutocompleteItem(x)));
const statusFilters = computed(() =>
    ['All', ...Object.keys(MessageStatus)].map(x => {
        return { title: t(`messages.resources.filter${x}`), value: x, data: x };
    })
);

function initFilters(filters: MessageListFilters) {
    statusModel.value = statusFilters.value.find(x => (filters.status ? x.value === filters.status : x.value === 'All'));
    lineModel.value = linesFilters.value.find(x => (filters.lineName ? x.data.name === filters.lineName : x.data.name === 'All'));
    companyModel.value = companiesFilters.value.find(x =>
        filters.companyNumber ? x.data.companyNumber === filters.companyNumber : x.data.name === 'All'
    );
    categoryModel.value = categoriesFilters.value.find(x => (filters.categoryName ? x.value === filters.categoryName : x.value === 'All'));
}

function resetCategoryFilter() {
    categoryModel.value = categoriesFilters.value.find(x => x.value === 'All');
}

defineExpose({
    initFilters,
    resetCategoryFilter
});
</script>

<style lang="scss" scoped>
.filter-title {
    padding-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
}

.filters {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    .v-autocomplete {
        margin-right: 16px;
    }
}
</style>
