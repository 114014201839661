import { computed } from 'vue';
import i18n from '@/plugins/i18n';

export function useListHeaders() {
    const ticketingMachineHeaders = computed<any[]>(() => [
        {
            title: i18n.global.t('messages.resources.listLineName'),
            key: 'lineName',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listSubject'),
            key: 'category',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listReason'),
            key: 'reason',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listDeparture'),
            key: 'departure',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listPublished'),
            key: 'published',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listEndPublish'),
            key: 'validTo',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.driverId'),
            key: 'driverId',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listStatus'),
            key: 'status',
            sortable: false,
            align: 'end'
        }
    ]);

    const portalHeaders = computed<any[]>(() => [
        {
            title: i18n.global.t('messages.resources.listCategory'),
            key: 'category',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listReason'),
            key: 'reason',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listScope'),
            key: 'scope',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listPublished'),
            key: 'published',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listStartPublish'),
            key: 'validFrom',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listEndPublish'),
            key: 'validTo',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listAuthor'),
            key: 'author',
            sortable: false
        },
        {
            title: i18n.global.t('messages.resources.listStatus'),
            key: 'status',
            sortable: false,
            align: 'end'
        }
    ]);

    return { ticketingMachineHeaders, portalHeaders };
}
