<template>
    <v-skeleton-loader v-if="loading" :transition-group="'fade-transition'" type="paragraph"></v-skeleton-loader>
    <div v-else class="page-container">
        <div class="page-content">
            <line-scope
                v-if="selectedScope?.value === MessageScope.Lines"
                v-model="message.receivers.lines"
                :lines="lines"
                :loading="loading"
            >
                <template #scope-select>
                    <v-autocomplete
                        v-if="scopesFromTemplates.length > 1"
                        v-model="selectedScope"
                        :items="scopesFromTemplates"
                        :label="t('messages.resources.scope', 'Omfang')"
                        max-width="330"
                        color="primary"
                        variant="outlined"
                        data-id="ScopeAutoComplete"
                        return-object
                    >
                        <template #item="{ item, props: itemProps }: { item: any, props: any }">
                            <v-list-item v-bind="itemProps" :data-id="`Scope${item.value}`"></v-list-item>
                        </template>
                    </v-autocomplete>
                </template>
            </line-scope>
            <stops-scope
                v-if="selectedScope?.value === MessageScope.Stops"
                v-model:selected-stops="message.receivers.stops"
                v-model:stops-search="stopsSearch"
                :stops="stops"
                :loading="loadingStops"
            >
                <template #scope-select>
                    <v-autocomplete
                        v-if="scopesFromTemplates.length > 1"
                        v-model="selectedScope"
                        :items="scopesFromTemplates"
                        :label="t('messages.resources.scope', 'Omfang')"
                        max-width="330"
                        color="primary"
                        variant="outlined"
                        data-id="ScopeAutoComplete"
                        return-object
                    >
                        <template #item="{ item, props: itemProps }: { item: any, props: any }">
                            <v-list-item v-bind="itemProps" :data-id="`Scope${item.value}`"></v-list-item>
                        </template>
                    </v-autocomplete>
                </template>
            </stops-scope>
            <company-scope
                v-if="selectedScope?.value === MessageScope.Companies"
                v-model="message.receivers.companies"
                :companies="companies"
            >
                <template #scope-select>
                    <v-autocomplete
                        v-if="scopesFromTemplates.length > 1"
                        v-model="selectedScope"
                        :items="scopesFromTemplates"
                        :label="t('messages.resources.scope', 'Omfang')"
                        max-width="330"
                        color="primary"
                        variant="outlined"
                        data-id="ScopeAutoComplete"
                        return-object
                    >
                        <template #item="{ item, props: itemProps }: { item: any, props: any }">
                            <v-list-item v-bind="itemProps" :data-id="`Scope${item.value}`"></v-list-item>
                        </template>
                    </v-autocomplete>
                </template>
            </company-scope>
            <driver-scope v-if="selectedScope?.value === MessageScope.Drivers" v-model="selectedDriverCompany" :companies="companies">
                <template #scope-select>
                    <v-autocomplete
                        v-if="scopesFromTemplates.length > 1"
                        v-model="selectedScope"
                        :items="scopesFromTemplates"
                        :label="t('messages.resources.scope', 'Omfang')"
                        max-width="330"
                        color="primary"
                        variant="outlined"
                        data-id="ScopeAutoComplete"
                        return-object
                    >
                        <template #item="{ item, props: itemProps }: { item: any, props: any }">
                            <v-list-item v-bind="itemProps" :data-id="`Scope${item.value}`"></v-list-item>
                        </template>
                    </v-autocomplete>
                </template>
            </driver-scope>
            <departure-scope
                v-if="selectedScope?.value === MessageScope.Departures"
                v-model="message.receivers.departures"
                :lines="lines"
                :service-journeys="serviceJourneys"
                :loading="loadingDepartures"
                @update-operating-day="onUpdateOperatingDay"
                @update-line="onUpdateLine"
            >
                <template #scope-select>
                    <v-autocomplete
                        v-if="scopesFromTemplates.length > 1"
                        v-model="selectedScope"
                        :items="scopesFromTemplates"
                        :label="t('messages.resources.scope', 'Omfang')"
                        color="primary"
                        variant="outlined"
                        data-id="ScopeAutoComplete"
                        return-object
                    >
                        <template #item="{ item, props: itemProps }: { item: any, props: any }">
                            <v-list-item v-bind="itemProps" :data-id="`Scope${item.value}`"></v-list-item>
                        </template>
                    </v-autocomplete>
                </template>
            </departure-scope>
            <v-autocomplete
                v-if="selectedScope?.value === MessageScope.Global"
                v-model="selectedScope"
                :items="scopesFromTemplates"
                :label="t('messages.resources.scope', 'Omfang')"
                max-width="330"
                color="primary"
                variant="outlined"
                data-id="ScopeAutoComplete"
                return-object
            >
                <template #item="{ item, props: itemProps }: { item: any, props: any }">
                    <v-list-item v-bind="itemProps" :data-id="`Scope${item.value}`"></v-list-item>
                </template>
            </v-autocomplete>
            <v-autocomplete
                v-if="!selectedScope"
                v-model="selectedScope"
                :items="scopesFromTemplates"
                :label="t('messages.resources.scope', 'Omfang')"
                max-width="330"
                color="primary"
                variant="outlined"
                data-id="ScopeAutoComplete"
                return-object
            >
                <template #item="{ item, props: itemProps }: { item: any, props: any }">
                    <v-list-item v-bind="itemProps" :data-id="`Scope${item.value}`"></v-list-item>
                </template>
            </v-autocomplete>
        </div>
        <div class="page-sidebar">
            <message-summary
                data-id="SubmitMessageButton"
                :title="categoryName"
                :submit-button-text="submitText"
                :is-submit-visible="isDataSelected"
                :submit-button-disabled="!isValid"
                @submit="publish"
                @back="back"
            >
                <line-scope-summary
                    v-if="selectedScope?.value === MessageScope.Lines"
                    :lines="message.receivers.lines"
                    @remove-line="onRemoveLine"
                ></line-scope-summary>
                <stops-scope-summary
                    v-if="selectedScope?.value === MessageScope.Stops"
                    :stops="message.receivers.stops"
                    @remove-stop="onRemoveStop"
                ></stops-scope-summary>
                <company-scope-summary
                    v-if="selectedScope?.value === MessageScope.Companies"
                    :companies="message.receivers.companies"
                    @remove-company="onRemoveCompany"
                ></company-scope-summary>
                <driver-scope-summary
                    v-if="selectedScope?.value === MessageScope.Drivers"
                    v-model="driverId"
                    :company="selectedDriverCompany"
                    @remove-driver-company="onRemoveDriverCompany"
                ></driver-scope-summary>
                <departure-scope-summary
                    v-if="selectedScope?.value === MessageScope.Departures"
                    :departures="message.receivers.departures"
                    @remove-departure="onRemoveDeparture"
                ></departure-scope-summary>
                <message-reason-summary
                    v-if="isDataSelected"
                    :message-templates="availableReasons"
                    @update-reason="onUpdateReason"
                    @update-reason-text="onUpdateReasonText"
                    @update-structured-message="onUpdateStructuredMesage"
                    @update-push-notifications="onPushNotificationsUpdate"
                ></message-reason-summary>
                <summary-date-edit
                    v-if="showPublishingSelection"
                    v-model:start-publish="message.startPublish"
                    v-model:end-publish="message.endPublish"
                    v-model:valid-from="message.validFrom"
                ></summary-date-edit>
                <div v-if="!selectedScope" class="summary-nodata">
                    {{
                        t(
                            'messages.resources.newMessageSummaryNoData',
                            'For å sette opp en ny melding velger du omfang og relevante filter til venstre, og finner alternativet ditt i tabellen under.'
                        )
                    }}
                </div>
            </message-summary>
        </div>
    </div>
</template>

<script lang="ts" setup>
import MessageSummary from './MessageSummary.vue';
import MessageReasonSummary from './MessageReasonSummary.vue';
import DepartureScope from './Scopes/DeparturesScope.vue';
import DepartureScopeSummary from './Scopes/DepartureScopeSummary.vue';
import LineScope from './Scopes/LineScope.vue';
import LineScopeSummary from './Scopes/LineScopeSummary.vue';
import StopsScope from './Scopes/StopsScope.vue';
import StopsScopeSummary from './Scopes/StopsScopeSummary.vue';
import CompanyScope from './Scopes/CompanyScope.vue';
import CompanyScopeSummary from './Scopes/CompanyScopeSummary.vue';
import DriverScope from './Scopes/DriverScope.vue';
import DriverScopeSummary from './Scopes/DriverScopeSummary.vue';
import SummaryDateEdit from './SummaryDateEdit.vue';
import { useMessagesStore } from '../messagesStore';
import { storeToRefs } from 'pinia';
import { useLinesStore } from '@/Features/Lines/linesStore';
import { useStopsStore } from '@/Features/Stops/stopsStore';
import { useCompaniesStore } from '@/Features/Companies/companiesStore';
import { useI18n } from 'vue-i18n';
import { FormatDateISO } from '@/shared/DateFormatHelpers';
import { computed, ref, watch } from 'vue';
import { Line } from '@/Features/Lines/linesModels';
import {
    DepartureReceiver,
    MessageTemplate,
    MessageScope,
    LineReceiver,
    StopReceiver,
    Receiver,
    DriverReceiver,
    StructuredMessage,
    CancellationCategoryName
} from '../messagesModels';
import { companyReceiversEqual, departureReceiversEqual, lineReceiversEqual, stopReceiversEqual } from '../helpers';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router';
import { useRouteQuery } from 'vue-use-route-query';
import _ from 'lodash';
import { AutocompleteItem } from '@/shared/models';

const { t } = useI18n();
const router = useRouter();

const messagesStore = useMessagesStore();
const linesStore = useLinesStore();
const stopsStore = useStopsStore();
const companiesStore = useCompaniesStore();

const { message, serviceJourneys, messageTemplates, categoryTemplates } = storeToRefs(messagesStore);
const { lines } = storeToRefs(linesStore);
const { stops } = storeToRefs(stopsStore);
const { companies } = storeToRefs(companiesStore);
const stopsSearch = ref('');
const selectedDriverCompany = ref<Receiver | null>(null);
const driverId = ref<string>();

const selectedMessageCategory = useRouteQuery('category', '');

const loading = ref(false);
const loadingDepartures = ref(false);
const loadingStops = ref(false);

const submitText = computed(() =>
    selectedMessageCategory.value === CancellationCategoryName
        ? t('messages.resources.cancelDepartures', 'Innstill avgang')
        : t('messages.resources.publish', 'Publisere')
);

const selectedOperatingDay = ref(FormatDateISO(new Date()));
const selectedLine = ref<Line | null>();

const selectedMessageTemplates = computed(() => messagesStore.getMessageTemplatesByCategory(selectedMessageCategory.value));
const scopesFromTemplates = computed(() =>
    messagesStore.getScopesFromMessageTemplates(selectedMessageTemplates.value).map(x => toAutocompleteItem(x))
);
function toAutocompleteItem(messageScope: MessageScope) {
    return { title: t(`messages.resources.${messageScope.toLowerCase()}`), value: messageScope.toString(), data: messageScope };
}
const selectedScope = ref<AutocompleteItem<MessageScope>>();
const availableReasons = computed(() =>
    messagesStore.filterMessageTemplatesByScope(selectedMessageTemplates.value, selectedScope.value?.data)
);

const showPublishingSelection = computed(() => isDataSelected.value && selectedScope.value?.data !== MessageScope.Departures);

const isDataSelected = computed(
    () =>
        message.value.receivers.departures.length > 0 ||
        message.value.receivers.lines.length > 0 ||
        message.value.receivers.stops.length > 0 ||
        message.value.receivers.areas.length > 0 ||
        message.value.receivers.companies.length > 0 ||
        message.value.receivers.drivers.length > 0 ||
        message.value.receivers.scopes[0] === MessageScope.Global
);
const isValid = computed<boolean>(() => validate());

watch(selectedScope, resetReceivers);
watch(stopsSearch, await onSearchStops);
watch(selectedDriverCompany, onDriverCompanyUpdate);
watch(driverId, onDriverUpdate);

async function onSearchStops(query: string) {
    if (!query) {
        return;
    }

    loadingStops.value = true;
    stopsSearch.value = query;
    await stopsStore.searchStops(query);
    loadingStops.value = false;
}

await load();

const categoryName = computed(() => {
    const category = categoryTemplates.value.find(x => x.name === selectedMessageCategory.value);
    return category?.label || '';
});

const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

async function load() {
    if (categoryTemplates.value.length < 1) await messagesStore.loadMessageCategories();
    if (messageTemplates.value.length < 1) await messagesStore.loadMessageTemplates();
    if (lines.value.length < 1) await linesStore.loadLines();
    if (companies.value.length < 1) await companiesStore.loadCompanies();
    if (scopesFromTemplates.value.length === 1) selectedScope.value = scopesFromTemplates.value[0];
    await messagesStore.initMessage();
    message.value.startPublish = new Date();
    message.value.endPublish = new Date(new Date().getTime() + weekInMilliseconds);
    message.value.validFrom = new Date();
    await loadServiceJourneys();
}

function resetReceivers(scope) {
    message.value.receivers = {
        lines: [],
        stops: [],
        areas: [],
        companies: [],
        drivers: [],
        departures: [],
        scopes: [scope.data]
    };
}

async function onUpdateOperatingDay(newOperatingDay: string) {
    selectedOperatingDay.value = newOperatingDay;
    await loadServiceJourneys();
}

async function onUpdateLine(newLine: Line | null) {
    selectedLine.value = newLine;
    await loadServiceJourneys();
}

function onDriverCompanyUpdate(company) {
    updateDriverReceiver(company, driverId.value);
}

function onDriverUpdate(driver) {
    updateDriverReceiver(selectedDriverCompany.value, driver);
}

function updateDriverReceiver(company?: Receiver | null, driver?: string) {
    if (driver && company) {
        const driverReceiver: DriverReceiver = {
            name: driver,
            id: driver,
            companyId: company.id,
            companyName: company.name ?? ''
        };
        message.value.receivers.drivers = [driverReceiver];
    } else {
        message.value.receivers.drivers = [];
    }
}

async function onRemoveDeparture(oldDeparture: DepartureReceiver) {
    const index = message.value.receivers.departures.findIndex(x => departureReceiversEqual(x, oldDeparture));
    if (index > -1) {
        message.value.receivers.departures.splice(index, 1);
    }
}

async function onRemoveLine(oldLine: LineReceiver) {
    const index = message.value.receivers.lines.findIndex(x => lineReceiversEqual(x, oldLine));
    if (index > -1) {
        message.value.receivers.lines.splice(index, 1);
    }
}

async function onRemoveStop(oldStop: StopReceiver) {
    const index = message.value.receivers.stops.findIndex(x => stopReceiversEqual(x, oldStop));
    if (index > -1) {
        message.value.receivers.stops.splice(index, 1);
    }
}

async function onRemoveCompany(oldCompany: Receiver) {
    const index = message.value.receivers.companies.findIndex(x => companyReceiversEqual(x, oldCompany));
    if (index > -1) {
        message.value.receivers.companies.splice(index, 1);
    }
}

function onRemoveDriverCompany() {
    selectedDriverCompany.value = null;
    message.value.receivers.drivers = [];
}

async function loadServiceJourneys() {
    if (selectedOperatingDay.value && selectedLine.value) {
        loadingDepartures.value = true;
        await messagesStore.loadServiceJourneys({ date: selectedOperatingDay.value, linePrivateCode: selectedLine.value.privateCode });
        loadingDepartures.value = false;
    } else {
        messagesStore.serviceJourneys = [];
    }
}

async function publish() {
    loading.value = true;
    try {
        message.value.shouldPublish = true;
        await messagesStore.createMessage();
        await router.push({ name: routeNames.messages });
    } catch {
        loading.value = false;
    }
}

function validate(): boolean {
    const theMessage = message.value;
    const templateId = theMessage.structuredMessage.templateId;
    const messageTemplate = messageTemplates.value.find(x => x.id == templateId);
    const isBodyValid = messageTemplate?.editable ? !!theMessage.body && theMessage.body.trim() != '' : true;
    const scope = selectedScope.value?.data.toString().toLowerCase() || '';
    const hasReceiverInfo = theMessage?.receivers[scope]?.length > 0 || theMessage?.receivers.scopes[0] === MessageScope.Global;
    const hasPublishingTimes =
        !!(theMessage.startPublish && theMessage.endPublish) || theMessage?.receivers.scopes[0] === MessageScope.Departures;

    const hasChoices = messageTemplate?.choiceTemplates.length;
    const validChoices = (hasChoices && !_.isEmpty(theMessage.structuredMessage?.choice)) || !hasChoices;

    return hasReceiverInfo && !!messageTemplate && isBodyValid && hasPublishingTimes && validChoices;
}

function back() {
    router.push({ name: routeNames.messages });
}

function onUpdateReason(messageTemplate?: MessageTemplate) {
    message.value.structuredMessage.templateId = messageTemplate?.id ?? '';
}

function onUpdateReasonText(text: string) {
    message.value.body = text;
}

function onUpdateStructuredMesage(structuredMessage: StructuredMessage) {
    message.value.structuredMessage = structuredMessage;
}

function onPushNotificationsUpdate(pushNotifications: boolean) {
    message.value.pushNotifications = pushNotifications;
}
</script>

<style lang="scss" scoped>
.page-container {
    margin-top: 64px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}

.page-content {
    flex: 1;
    min-width: 600px;
}

.page-sidebar {
    flex: 0 0 360px;
}

.summary-nodata {
    font-size: 14px;
}
</style>
