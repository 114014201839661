<template>
    <div class="messagesList">
        <v-main>
            <v-row>
                <v-col>
                    <v-row class="controls">
                        <v-btn class="white--text" color="primary" data-id="AddNewButton" @click="createMessage">
                            {{ t('messages.resources.newMessage') }}
                        </v-btn>
                    </v-row>
                    <v-row class="controls">
                        <v-col cols="6">
                            <v-text-field
                                v-model="messagesStore.query"
                                append-inner-icon="search"
                                color="primary"
                                data-id="SearchTextField"
                                hide-details
                                label="Søk"
                                single-line
                                :clearable="true"
                            ></v-text-field>
                        </v-col>
                        <v-col class="source">
                            <v-btn
                                :class="{
                                    activeBtn: messagesStore.sourceSystem === 'NotificationHub'
                                }"
                                color="primary"
                                data-id="SourceButton"
                                variant="outlined"
                                rounded
                                @click="setSource('NotificationHub')"
                            >
                                {{ t('messages.resources.fromNotificationHub') }}
                            </v-btn>
                            <v-btn
                                :class="{
                                    activeBtn: messagesStore.sourceSystem === 'TicketingMachine'
                                }"
                                color="primary"
                                data-id="SourceButton"
                                variant="outlined"
                                rounded
                                @click="setSource('TicketingMachine')"
                            >
                                {{ t('messages.resources.fromTicketingMachine') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="computedHeaders"
                        :items="messagesStore.messages"
                        :items-per-page="DefaultPageSize"
                        :loading="loadingTable"
                        :page="page"
                        :row-props="rowProps"
                        data-id="DataTable"
                        disable-sort
                    >
                        <template #[`item.startPublish`]="{ item }">
                            {{ formatDate(item.startPublish) }}
                        </template>
                        <template #[`item.endPublish`]="{ item }">
                            {{ formatDate(item.endPublish) }}
                        </template>
                        <template #[`item.departureTime`]="{ item }">
                            {{ formatDate(item.departureTime) }}
                        </template>
                        <template #[`item.status`]="{ item }">
                            <v-tooltip bottom>
                                <template #activator="">
                                    <v-icon
                                        :color="getStatusIconColor(item.status)"
                                        :icon="getStatusIcon(item.status)"
                                        size="small"
                                    ></v-icon>
                                </template>
                                <span>{{ getStatusTooltip(item.status) }}</span>
                            </v-tooltip>
                        </template>
                        <template #[`item.reason`]="{ item }">
                            <!-- eslint-disable vue/no-v-html -->
                            <span v-html="item.reason"></span>
                            <!--eslint-enable-->
                        </template>
                        <template #[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template #activator="{ props }">
                                    <v-icon
                                        class="mr-2"
                                        data-id="EditIcon"
                                        style="outline: none"
                                        v-bind="props"
                                        icon="mdi-pencil"
                                        @click="editItem(item.id)"
                                    ></v-icon>
                                </template>
                                <span>{{ t('shared.resources.common.edit') }}</span>
                            </v-tooltip>
                        </template>
                        <template #bottom></template>
                    </v-data-table>
                    <v-row>
                        <v-col cols="8">
                            <div v-if="messagesStore.paging.totalItemCount !== undefined" class="totalMessages">
                                {{ t('messages.resources.messages') + ': ' + messagesStore.paging.totalItemCount }}
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <v-pagination v-model="page" :length="messagesStore.paging.pageCount"></v-pagination>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script lang="ts" setup>
import { DateToUTC, ToLocalDate } from '@/shared/DateFormatHelpers';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { DefaultPageSize } from '@/shared/models';
import { useMessagesStore } from './messagesStore';
import { storeToRefs } from 'pinia';
import _ from 'lodash';
import { MessageStatus } from '@/Features/Messages/messagesModels';
import { CancellationCategoryName } from '../DeviationMessages/messagesModels';
import { routeNames } from '@/router';
import { useCookies } from '@vueuse/integrations/useCookies';
import { CookieSettings, CookieConstants } from '@/shared/models';

const messagesStore = useMessagesStore();
const { t } = useI18n();
const router = useRouter();
const cookies = useCookies([CookieConstants.settingsName]);

await redirectOperatorEditors();

const loadingTable = ref(true);
const page = ref(1);
const updating = ref(false);
const { query } = storeToRefs(messagesStore);
const headersNotifHub = computed(() => [
    {
        title: t('messages.resources.category'),
        key: 'subject',
        sortable: false
    },
    {
        title: t('messages.resources.sentTo'),
        key: 'sentTo',
        sortable: false
    },
    {
        title: t('messages.resources.startPublishing'),
        key: 'startPublish',
        sortable: false
    },
    {
        title: t('messages.resources.endPublishing'),
        key: 'endPublish',
        sortable: false
    },
    {
        title: t('messages.resources.isPublished'),
        key: 'status',
        sortable: false
    },
    {
        title: t('shared.resources.common.edit'),
        key: 'action',
        sortable: false
    }
]);
const headersDriverMsg = computed(() => [
    {
        title: t('messages.resources.line'),
        key: 'line',
        sortable: false
    },
    {
        title: t('messages.resources.subject'),
        key: 'subject',
        sortable: false
    },
    {
        title: t('messages.resources.publishFrom'),
        key: 'startPublish',
        sortable: false
    },
    {
        title: t('messages.resources.publishTo'),
        key: 'endPublish',
        sortable: false
    },
    {
        title: t('messages.resources.from'),
        key: 'from',
        sortable: false
    },
    {
        title: t('messages.resources.departure'),
        key: 'departureTime',
        sortable: false
    },
    {
        title: t('messages.resources.reason'),
        key: 'reasonNo',
        sortable: false
    },
    {
        title: t('messages.resources.driverId'),
        key: 'driverId',
        sortable: false
    },
    {
        title: t('messages.resources.isPublished'),
        key: 'status',
        sortable: false
    },
    {
        title: t('shared.resources.common.edit'),
        key: 'action',
        sortable: false
    }
]);

const currentDate = computed(() => DateToUTC(new Date()));
const computedHeaders = computed(() =>
    messagesStore.sourceSystem === 'TicketingMachine' ? headersDriverMsg.value : headersNotifHub.value
);

let cookieSettings: CookieSettings = cookies.get(CookieConstants.settingsName);
if (cookieSettings) {
    if (cookieSettings.sourceSystem) {
        messagesStore.sourceSystem = cookieSettings.sourceSystem;
    } else {
        // add missing setting
        cookieSettings.sourceSystem = messagesStore.sourceSystem;
        cookies.set(CookieConstants.settingsName, cookieSettings);
        // get updated settings object
        cookieSettings = cookies.get(CookieConstants.settingsName);
    }
} else {
    const settings: CookieSettings = {
        sourceSystem: messagesStore.sourceSystem
    };
    cookies.set(CookieConstants.settingsName, settings);
    // get updated settings object
    cookieSettings = cookies.get(CookieConstants.settingsName);
}

await messagesStore.loadMessages(page.value);
loadingTable.value = false;

watch(
    query,
    _.debounce(async () => {
        if (updating.value) return;
        loadingTable.value = true;
        await messagesStore.loadMessages(1);
        loadingTable.value = false;
    }, 500)
);

watch(page, async () => {
    if (updating.value) return;
    loadingTable.value = true;
    await messagesStore.loadMessages(page.value);
    loadingTable.value = false;
});

async function redirectOperatorEditors() {
    if (messagesStore.categoryTemplates.length < 1) await messagesStore.loadMessageCategories();
    if (messagesStore.categoryTemplates.length != 1) return;
    const category = messagesStore.categoryTemplates[0];
    if (category.name != CancellationCategoryName) return;
    router.push({ name: routeNames.messages });
}

function getStatusIconColor(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return 'primary';
    }

    if (status === MessageStatus.Unpublished) {
        return 'red-darken-4';
    }
    return 'grey';
}

function getStatusIcon(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return 'mdi-check-circle';
    }

    if (status === MessageStatus.Unpublished) {
        return 'mdi-close-circle';
    }
    return 'mdi-circle';
}

function getStatusTooltip(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return t('messages.resources.published');
    }

    if (status === MessageStatus.Unpublished) {
        return t('messages.resources.unpublished');
    }
    return t('messages.resources.draft');
}

async function setSource(system: string) {
    messagesStore.sourceSystem = system;
    cookieSettings.sourceSystem = system;

    cookies.set(CookieConstants.settingsName, cookieSettings);
    await clearSearch();
}

function rowProps(row: any) {
    const rowProp = {
        'data-message-id': row.item.id
    };

    if (isActive(row.item)) {
        return { ...rowProp, ...{ class: 'active-message' } };
    }
    return rowProp;
}

async function clearSearch() {
    updating.value = true;
    messagesStore.query = '';
    page.value = 1;
    loadingTable.value = true;
    await messagesStore.loadMessages(page.value);
    loadingTable.value = false;
    updating.value = false;
}

function editItem(item: any) {
    router.push({ path: `/messages/${item}` });
}

function createMessage() {
    router.push({ path: `/messages/create` });
}

function formatDate(date?: string) {
    if (!date) {
        return '';
    } else {
        return ToLocalDate(date);
    }
}

function isActive(message: any) {
    const startPublish = DateToUTC(message.startPublish);
    const endPublish = DateToUTC(message.endPublish);
    return startPublish < currentDate.value && currentDate.value < endPublish;
}
</script>

<style lang="scss" scoped>
@import '../../shared/variables.scss';

.totalMessages {
    color: $grey;
}

.controls {
    justify-content: flex-end;
    margin: 12px 0;

    .source {
        display: flex;
        justify-content: flex-end;
        padding-right: 0;
    }

    button {
        margin-left: 10px;
    }

    .activeBtn {
        color: #ffffff !important;
        background-color: $primary;
    }
}
</style>

<style lang="scss">
.v-data-table td.text-start:nth-child(7) {
    max-width: 450px;
    overflow: hidden;
}
</style>
