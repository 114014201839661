import { Link } from '@/shared/models';

export interface Message {
    id?: string;
    subject: string;
    body?: string;
    subjectEn?: string;
    bodyEn?: string;
    pushNotifications: boolean;
    notificationType?: MessageNotificationType;
    status: MessageStatus;
    createdAt?: string;
    channels: Array<string>;
    receivers: Receivers;
    startPublish?: string;
    endPublish?: string;
    structuredMessage: {
        templateId: string;
        input: {
            [key: string]: string;
        };
        choice: {
            [key: string]: string;
        };
        comment?: string;
    };
    hasChangedSincePublish: boolean;
    author?: string;
    sourceSystem?: string;
}

export interface MessageListItem extends Message {
    departureTime?: string;
    reason?: string;
    reasonNo?: string;
    links: Link[];
}

export interface Receiver {
    id: string;
    name?: string;
}

export interface DriverReceiver extends Receiver {
    companyId: string;
}

export interface DepartureReceiver extends Receiver {
    departureTime: string;
    operatingDay: string;
}

export interface LineReceiver extends Receiver {
    transportType: string;
}

export interface StopReceiver extends Receiver {
    fullName: string;
    publicCode: string;
}

export interface Receivers {
    scopes: Array<string>;
    lines: Array<LineReceiver>;
    stops: Array<StopReceiver>;
    areas: Array<Receiver>;
    companies: Array<Receiver>;
    drivers: Array<DriverReceiver>;
    departures: Array<DepartureReceiver>;
}

export interface MessageFormInstepContent {
    subtitle?: string;
    contentType: string;
}

export const Scopes = {
    Areas: 'Areas',
    Departures: 'Departures',
    Drivers: 'Drivers',
    Lines: 'Lines',
    Companies: 'Companies',
    Stops: 'Stops'
};

export enum MessageStatus {
    Created = 'Created',
    Published = 'Published',
    Unpublished = 'Unpublished'
}

export enum FormSteps {
    InstepOffset = 0.5,
    Categories = 0,
    Reason = 1,
    Scope = 2,
    Publishing = 3
}

export enum MessageNotificationType {
    Information = 0,
    Warning = 1,
    Cancellation = 2,
    EarlyTripEnd = 3,
    EquipmentFailure = 4,
    Full = 5,
    OnboardTicketMachine = 6,
    AtStopOther = 7
}

export interface MessageTemplate {
    id: string;
    name: string;
    label: string;
    categoryName: string;
    categoryLabel: string;
    messageScopes: MessageScope[];
    editable: boolean;
    pushAvailable: boolean;
    transportTypes: TransportType[];
    notificationType: MessageNotificationType;
    inputTemplates: MessageInputTemplate[];
    choiceTemplates: MessageChoiceTemplate[];
    isFirstLevel: boolean;
}

export interface MessageInputTemplate {
    name: string;
    label: string;
    inputType: MessageInputType;
}

export interface MessageChoiceTemplate {
    name: string;
    label: string;
    group: string;
    groupLabel: string;
}

export interface MessageCategoryTemplate {
    id: string;
    name: string;
    label: string;
}

export interface ServiceJourney {
    tripId: string;
    linePrivateCode: string;
    lineName: string;
    departure: string;
    destination: string;
    departureAt: string;
    companyNumber: string;
}

export enum MessageInputType {
    ShortText,
    Number
}

export enum MessageScope {
    Global,
    Lines,
    Departures,
    Stops,
    Areas,
    Companies,
    Drivers
}

export enum TransportType {
    None = 0,
    Bus = 1,
    Water = 2,
    Rail = 3,
    Bike = 4,
    Unknown = 5
}
