<template>
    <v-container>
        <div class="control-panel">
            <div>
                <div class="controls">
                    <div class="source-switch-wrap">
                        <v-select
                            v-model="sourceModel"
                            class="source-select"
                            :items="sourceMenuItems"
                            return-object
                            color="primary"
                            variant="outlined"
                            persistent-placeholder
                            data-id="SourcesSwitch"
                            hide-details
                        >
                            <template #item="{ item, props: itemProps }: { item: any, props: any }">
                                <v-list-item v-bind="itemProps" :data-id="`Source${item.value}`"></v-list-item>
                            </template>
                        </v-select>
                    </div>
                    <div class="search-wrap">
                        <v-text-field
                            v-model="searchQuery"
                            class="search"
                            append-inner-icon="mdi-magnify"
                            color="primary"
                            data-id="SearchTextField"
                            hide-details
                            variant="outlined"
                            :label="t('messages.resources.searchInTable')"
                            :clearable="true"
                        ></v-text-field>
                    </div>
                </div>
                <list-filters
                    ref="listFilterRef"
                    v-model:company="companiesFilterModel"
                    v-model:category="categoriesFilterModel"
                    v-model:line="linesFilterModel"
                    v-model:status="statusFilterModel"
                    :companies="companiesWithAll"
                    :categories="categoryFilterItems"
                    :lines="linesWithAll"
                />
            </div>
            <div class="new-message-wrap">
                <v-btn
                    v-if="hasOnlyOneCategory"
                    class="create-message"
                    color="primary"
                    data-id="AddNewButton"
                    @click="createMessage(categoryTemplates[0].name)"
                >
                    {{ t(`messages.resources.new${categoryTemplates[0].name}`) }}
                </v-btn>
                <v-btn v-else class="create-message" color="primary" data-id="AddNewButton">
                    <span>
                        {{ createMessageMenu ? t(`messages.resources.selectDeviationType`) : t(`messages.resources.${createButtonText}`) }}
                    </span>
                    <v-icon size="large">{{ createMessageMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    <v-menu v-model="createMessageMenu" activator="parent">
                        <v-list>
                            <v-list-item v-for="category in categoryTemplates" :key="category.id" :value="category.name">
                                <v-list-item-title :data-id="`Category${category.name}`" @click="createMessage(category.name)">
                                    {{ category.label }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn>
            </div>
        </div>
        <v-row no-gutters>
            <v-col class="table-wrap" :class="{ showSummary: showSummary }">
                <v-data-table
                    :headers="tableHeaders"
                    :items="tableRows"
                    :items-per-page="DefaultPageSize"
                    :loading="loadingTable"
                    :page="page"
                    :row-props="rowProps"
                    data-id="DataTable"
                    disable-sort
                    @click:row="openSummary"
                >
                    <template #[`item.status`]="{ item }">
                        <v-tooltip bottom>
                            <template #activator="{ props: itemProps }">
                                <v-icon
                                    :color="getStatusIconColor(item.status)"
                                    :icon="getStatusIcon(item.status)"
                                    v-bind="itemProps"
                                    size="small"
                                ></v-icon>
                            </template>
                            <span>{{ getStatusTooltip(item.status) }}</span>
                        </v-tooltip>
                    </template>
                    <template #bottom>
                        <div class="pagination-wrap">
                            <div v-if="messagesStore.paging.totalItemCount !== undefined" class="totalMessages">
                                {{ t('messages.resources.messages') + ': ' + messagesStore.paging.totalItemCount }}
                            </div>
                            <div class="paging-wrap">
                                <v-pagination v-model="page" :length="messagesStore.paging.pageCount"></v-pagination>
                            </div>
                        </div>
                    </template>
                </v-data-table>
                <div v-if="showSummary">
                    <message-summary
                        v-model="messagesStore.message"
                        :loading="loadingMessage"
                        :loading-update="loadingMessageUpdate"
                        :message-template="messagesStore.currentMessageTemplate"
                        @update-message="updateMessage"
                        @close-summary="closeSummary"
                        @unpublish="unpublishMessage"
                        @publish="publishMessage"
                    />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useMessagesStore } from '../messagesStore';
import { useLinesStore } from '@/Features/Lines/linesStore';
import { useCompaniesStore } from '@/Features/Companies/companiesStore';
import { DefaultPageSize, AutocompleteItem } from '@/shared/models';
import { DateToUTC } from '@/shared/DateFormatHelpers';
import { ToLocalDate } from '@/shared/DateFormatHelpers';
import { MessageCategoryTemplate, MessageListItem, MessageSource, MessageSourceLink } from '../messagesModels';
import { MessageStatus } from '@/Features/DeviationMessages/messagesModels';
import MessageSummary from './MessageSummary.vue';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router';
import { useRouteQuery, integerTransformer } from 'vue-use-route-query';
import { Line } from '@/Features/Lines/linesModels';
import { Company } from '@/Features/Companies/companiesModels';
import { useListHeaders } from './useListHeaders';
import ListFilters from './ListFilters.vue';

interface TableRow {
    category: string;
    reason: string;
    lineName: string;
    departureTime: string;
    departure: string;
    validFrom: string;
    validTo: string;
    scope?: string;
    author: string;
    status: MessageStatus;
    data: MessageListItem;
}

const router = useRouter();
const { t } = useI18n();
const messagesStore = useMessagesStore();
const linesStore = useLinesStore();
const companiesStore = useCompaniesStore();

const page = useRouteQuery('page', 1, integerTransformer, { mode: 'push' });
const selectedMessageId = useRouteQuery('messageId', '');
const searchQuery = useRouteQuery('search', '');
const sourceQuery = useRouteQuery('source', '');
const filterStatus = useRouteQuery('status', '');
const filterLine = useRouteQuery('line', '');
const filterCompany = useRouteQuery('company', '');
const filterCategory = useRouteQuery('category', '');

const createMessageMenu = ref(false);

const { query, categoryTemplates, source, sourceLinks, filters, categoryFilterItems } = storeToRefs(messagesStore);
const { linesWithAll } = storeToRefs(linesStore);
const { companiesWithAll } = storeToRefs(companiesStore);
const currentDate = computed(() => DateToUTC(new Date()));
const loadingTable = ref(true);
const showSummary = ref(false);
const selectedRowId = ref('');
const hasOnlyOneCategory = computed(() => categoryTemplates.value.length === 1);
const createButtonText = computed(() => (hasOnlyOneCategory.value ? `new${categoryTemplates.value[0].name}` : 'newMessage'));
const loadingMessage = ref(false);
const loadingMessageUpdate = ref(false);

const { ticketingMachineHeaders, portalHeaders } = useListHeaders();
const tableHeaders = computed<any[]>(() =>
    source.value === MessageSource.TicketingMachine ? ticketingMachineHeaders.value : portalHeaders.value
);
const sourceModel = ref<AutocompleteItem<MessageSourceLink>>();
const sourceMenuItems = ref<AutocompleteItem<MessageSourceLink>[]>();

const listFilterRef = ref<InstanceType<typeof ListFilters> | null>(null);
const statusFilterModel = ref<AutocompleteItem<string>>();
const linesFilterModel = ref<AutocompleteItem<Line>>();
const companiesFilterModel = ref<AutocompleteItem<Company>>();
const categoriesFilterModel = ref<AutocompleteItem<MessageCategoryTemplate | null>>();

const tableRows = computed<TableRow[]>(() => messagesStore.messages.map(x => toMessageListTableRow(x)));

onMounted(async () => {
    query.value = searchQuery.value;
    if (sourceQuery.value.length) source.value = sourceQuery.value as MessageSource;
    if (filterStatus.value.length) filters.value.status = filterStatus.value;
    if (filterLine.value.length) filters.value.lineName = filterLine.value;
    if (filterCompany.value.length) filters.value.companyNumber = filterCompany.value;
    if (filterCategory.value.length) filters.value.categoryName = filterCategory.value;
    if (messagesStore.categoryTemplates.length < 1) await messagesStore.loadMessageCategories();
    if (messagesStore.messageTemplates.length < 1) await messagesStore.loadMessageTemplates();
    if (!linesWithAll.value.length) await linesStore.loadLines();
    if (!companiesWithAll.value.length) await companiesStore.loadCompanies();
    await messagesStore.loadMessages(page.value);
    await messagesStore.loadMessageCategoriesBySource();
    await openSelectedSummary();

    sourceMenuItems.value = sourceLinks.value.map(x => toSelectItem(x));
    sourceModel.value = sourceMenuItems.value.find(x => x.value === source.value);
    listFilterRef.value?.initFilters(filters.value);

    loadingTable.value = false;
});

watch(linesFilterModel, async newVal => {
    if (newVal) {
        await updateFilter(newVal.data.name, x => {
            // update store filter and URL param
            filters.value.lineName = x;
            filterLine.value = x;
        });
    }
});

watch(categoriesFilterModel, async newVal => {
    if (newVal) {
        await updateFilter(newVal.value, x => {
            filters.value.categoryName = x;
            filterCategory.value = x;
        });
    }
});

watch(companiesFilterModel, async newVal => {
    if (newVal) {
        await updateFilter(newVal.data.companyNumber, x => {
            filters.value.companyNumber = x;
            filterCompany.value = x;
        });
    }
});

watch(statusFilterModel, async newVal => {
    if (newVal) {
        await updateFilter(newVal.value, x => {
            filters.value.status = x;
            filterStatus.value = x;
        });
    }
});

async function updateFilter(value: string, callback: Function) {
    loadingTable.value = true;
    const newValue = value === 'All' || value === '0' ? '' : value;
    callback(newValue);
    await messagesStore.loadMessages(1);
    loadingTable.value = false;
}

watch(sourceModel, async (newVal?: AutocompleteItem<MessageSourceLink>, oldVal?: AutocompleteItem<MessageSourceLink>) => {
    if (newVal) {
        sourceQuery.value = newVal.value;
        source.value = newVal.data.source;
        closeSummary();
        loadingTable.value = true;

        // don't update category filter on page load
        if (oldVal) {
            await messagesStore.loadMessageCategoriesBySource();
            listFilterRef.value?.resetCategoryFilter();
        }

        await messagesStore.loadMessages(1);
        loadingTable.value = false;
    }
});

function toSelectItem(link: MessageSourceLink): AutocompleteItem<MessageSourceLink> {
    return {
        title: t(`messages.resources.${link.translationKey}`),
        value: link.source,
        data: link
    };
}

function toMessageListTableRow(message: MessageListItem) {
    return {
        category: message.categoryNameNo,
        reason: message.reasonNo,
        lineName: message.lineName || '',
        departureTime: ToLocalDate(message.departureTime, 'time') || '',
        departure: message.departure || '',
        scope: message.sentTo,
        validFrom: `${ToLocalDate(message.validFrom, 'date')} - ${ToLocalDate(message.validFrom, 'time')}`,
        validTo: `${ToLocalDate(message.validTo, 'date')} - ${ToLocalDate(message.validTo, 'time')}`,
        published: `${ToLocalDate(message.publishedAt, 'date')} - ${ToLocalDate(message.publishedAt, 'time')}`,
        author: message.author || '',
        driverId: message.driverId,
        status: message.status,
        data: message
    };
}

watch(
    searchQuery,
    _.debounce(async val => {
        loadingTable.value = true;
        page.value = 1;
        query.value = val;
        closeSummary();
        await messagesStore.loadMessages(page.value);
        loadingTable.value = false;
    }, 500)
);

watch(page, async () => {
    loadingTable.value = true;
    closeSummary();
    await messagesStore.loadMessages(page.value);
    loadingTable.value = false;
});

async function openSummary(_, row) {
    await openSummaryById(row.item.data.id);
}

async function openSummaryById(id: string) {
    loadingMessage.value = true;
    showSummary.value = true;
    selectedRowId.value = id;
    selectedMessageId.value = id;
    await messagesStore.loadMessage(id);
    loadingMessage.value = false;
}

async function updateMessage() {
    loadingMessageUpdate.value = true;
    await messagesStore.updateMessage();
    loadingMessageUpdate.value = false;
}

function closeSummary() {
    showSummary.value = false;
    selectedRowId.value = '';
    selectedMessageId.value = '';
}

async function unpublishMessage() {
    setMessageLoading(true);
    await messagesStore.unpublishMessage();
    await messagesStore.loadMessages(page.value);
    setMessageLoading(false);
    closeSummary();
}

async function publishMessage() {
    setMessageLoading(true);
    await messagesStore.publishMessage();
    await messagesStore.loadMessages(page.value);
    setMessageLoading(false);
    closeSummary();
}

function setMessageLoading(isLoading: boolean) {
    if (isLoading) {
        loadingTable.value = true;
        loadingMessage.value = true;
    } else {
        loadingMessage.value = false;
        loadingTable.value = false;
    }
}

function getStatusIconColor(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return 'primary';
    }

    if (status === MessageStatus.Unpublished) {
        return 'red-darken-4';
    }
    return 'grey';
}

function getStatusIcon(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return 'mdi-check-circle';
    }

    if (status === MessageStatus.Unpublished) {
        return 'mdi-close-circle';
    }
    return 'mdi-circle';
}

function getStatusTooltip(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return t('messages.resources.published');
    }

    if (status === MessageStatus.Unpublished) {
        return t('messages.resources.unpublished');
    }
    return t('messages.resources.draft');
}

function rowProps(row: any) {
    const message = row.item.data as MessageListItem;
    return {
        'data-message-id': message.id,
        'data-message-groupid': message.groupId,
        class: getRowClass(message)
    };
}

function getRowClass(message: MessageListItem) {
    const classNames: string[] = [];

    if (isSelected(message)) {
        classNames.push('selected-message');
    }

    if (message.status != MessageStatus.Published) {
        classNames.push('unpublished-message');
    } else if (isActive(message)) {
        classNames.push('active-message');
    } else if (isExpired(message)) {
        classNames.push('expired-message');
    } else {
        classNames.push('future-message');
    }
    return classNames.join(' ');
}

function isSelected(message: MessageListItem) {
    return message.id === selectedRowId.value;
}

function isActive(message: MessageListItem) {
    if (!message.startPublish || !message.endPublish) return false;
    const startPublish = DateToUTC(message.startPublish);
    const endPublish = DateToUTC(message.endPublish);
    return startPublish < currentDate.value && currentDate.value < endPublish && message.status === MessageStatus.Published;
}

function isExpired(message: MessageListItem) {
    const endPublish = DateToUTC(message.endPublish);
    return currentDate.value > endPublish;
}

function createMessage(category: string) {
    router.push({ name: routeNames.messageCreate, query: { category: category } });
}

async function openSelectedSummary() {
    if (selectedMessageId.value) {
        const selectedRow = tableRows.value.find(x => x.data.id === selectedMessageId.value);
        if (selectedRow) {
            selectedRowId.value = selectedMessageId.value;
        }
        await openSummaryById(selectedMessageId.value);
    }
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.control-panel {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 42px;
    padding-bottom: 24px;

    .controls {
        display: grid;
        grid-template-columns: 50% 50%;
        padding-bottom: 22px;

        .search,
        .source-select {
            margin-right: 16px;
        }
    }

    .filter-title {
        padding-bottom: 6px;
        font-size: 14px;
        font-weight: 500;
    }

    .filters {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

        .v-autocomplete {
            margin-right: 16px;
        }
    }
}

@media screen and (max-width: 1038px) {
    .control-panel {
        grid-template-columns: 75% 25%;
    }
}

.new-message-wrap {
    .activeBtn {
        color: $white !important;
        background-color: $primary;
    }

    .create-message {
        float: right;
    }
}

.v-table :deep(.v-data-table-header__content) {
    font-size: 18px;
    font-weight: bold;
    color: $black;
}

.pagination-wrap {
    padding: 12px;

    .totalMessages {
        color: $grey;
        display: inline-block;
    }

    .paging-wrap {
        display: block;
        width: 33%;
        float: right;

        :deep(.v-pagination__list) {
            justify-content: right;
        }
    }
}

.table-wrap {
    display: grid;
    grid-template-columns: 100% 0;

    .item-summary {
        display: none;
    }

    &.showSummary {
        grid-template-columns: calc(100% - 372px) 372px;

        .item-summary {
            display: block;
        }
    }

    :deep(table) {
        .selected-message {
            td {
                border-top: 1px solid $primary;
                border-bottom: 1px solid $primary !important;

                &:first-child {
                    border-left: 1px solid $primary;
                }

                &:last-child {
                    border-right: 1px solid $primary;
                }
            }

            &.unpublished-message {
                td {
                    border-color: $red-darken-4 !important;
                }
            }
        }

        .future-message:hover {
            background-color: $darkenedWhite;
        }

        .unpublished-message {
            background-color: $disabledRed;

            &:hover {
                background-color: $darkenedRed;
            }
        }

        .active-message {
            background-color: $lightGreen;

            &:hover {
                background-color: $darkenedGreen;
            }
        }

        .expired-message {
            background-color: $lightGrey;

            &:hover {
                background-color: $darkenedGrey;
            }
        }
    }
}
</style>
