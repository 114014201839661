<template>
    <div class="summary-container">
        <div>
            <v-select
                v-model="selectedReason"
                :items="reasonItems"
                :label="t('messages.resources.reason', 'Årsak')"
                return-object
                color="primary"
                variant="outlined"
                persistent-placeholder
                data-id="ReasonSelector"
            >
                <template #item="{ item, props: itemProps }: { item: any, props: any }">
                    <v-list-item v-bind="itemProps" :data-id="`Reason${item.raw.data.name}`"></v-list-item>
                </template>
            </v-select>
        </div>
        <div v-if="choices.length">
            <v-radio-group v-if="inputs.length" v-model="radioChoice" class="choice-radio">
                <v-radio
                    v-for="(choice, i) in choices"
                    :key="i"
                    :data-id="`Choice${choice.name}`"
                    :label="choice.label"
                    :value="choice"
                    class="choice-option"
                ></v-radio>
            </v-radio-group>
            <div v-else>
                <v-select
                    v-model="selectedChoice"
                    :items="choicesItems"
                    :label="t('messages.resources.subreason', 'Underårsak')"
                    return-object
                    color="primary"
                    variant="outlined"
                    data-id="ChoicesSelector"
                ></v-select>
            </div>
        </div>
        <div v-if="inputs.length">
            <div v-for="input in inputs" :key="input.name" class="input-fields">
                <div class="label">{{ `${input.label}:` }}</div>
                <div class="field">
                    <v-text-field
                        v-model="structuredMessage.input[input.name]"
                        type="number"
                        hide-details
                        variant="outlined"
                        density="comfortable"
                        :data-id="`Input${input.name}`"
                    >
                        <v-icon v-if="input.inputType === MessageInputType.Number" class="input-up" @click="increaseInput(input.name)">
                            mdi-chevron-up
                        </v-icon>
                        <v-icon v-if="input.inputType === MessageInputType.Number" class="input-down" @click="decreaseInput(input.name)">
                            mdi-chevron-down
                        </v-icon>
                    </v-text-field>
                </div>
            </div>
        </div>
        <div v-if="hasEditor" class="editor">
            <editor
                v-model="reasonText"
                :init="{
                    entity_encoding: 'raw',
                    height: 270,
                    menubar: false,
                    language: 'nb_NO',
                    language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/nb_NO.js',
                    plugins: ['lists link'],
                    toolbar: 'bold italic | link | help',
                    content_css: '/tinymce.css',
                    placeholder: t('messages.resources.reasonTextPlaceholder', 'Skriv inn årsak til innstilling her for loggføring.')
                }"
                data-id="BodyEditor"
            />
        </div>
        <div v-if="selectedReason?.data.pushAvailable" class="push-notifications">
            <v-checkbox
                v-model="pushNotifications"
                :label="t('messages.resources.sendPushNotifications', 'Send pushvarsler')"
                data-id="pushNotifications"
                hide-details
            ></v-checkbox>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import Editor from '@tinymce/tinymce-vue';
import { AutocompleteItem } from '@/shared/models';
import { MessageTemplate, StructuredMessage, MessageInputType, MessageChoiceTemplate } from '../messagesModels';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

interface Props {
    messageTemplates: MessageTemplate[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
    (e: 'updateReason', messageTemplate?: MessageTemplate);
    (e: 'updateReasonText', text: string);
    (e: 'updateStructuredMessage', structuredMessage: StructuredMessage);
    (e: 'updatePushNotifications', push: boolean);
}>();

const selectedReason = ref<AutocompleteItem<MessageTemplate>>();
const reasonText = ref<string>('');

const reasonItems = computed(() => props.messageTemplates.map(x => toAutocompleteItem(x)));
const hasEditor = computed(() => selectedReason.value?.data.editable ?? false);
const inputs = computed(() => selectedReason.value?.data.inputTemplates || []);
const choices = computed(() => selectedReason.value?.data.choiceTemplates || []);
const choicesItems = computed(() => selectedReason.value?.data.choiceTemplates.map(x => toChoiceAutocompleteItem(x)) || []);
const radioChoice = ref<MessageChoiceTemplate>();
const selectedChoice = ref<AutocompleteItem<MessageChoiceTemplate>>();
const pushNotifications = ref(false);

const structuredMessage = ref<StructuredMessage>({
    templateId: '',
    input: {},
    choice: {}
});

onMounted(() => {
    if (reasonItems.value.length === 1) {
        selectedReason.value = reasonItems.value[0];
    }
});

// reset reason on scope change
watch(
    reasonItems,
    () => {
        if (reasonItems.value.length === 1) {
            selectedReason.value = reasonItems.value[0];
        }
    },
    { deep: true }
);

// set up v-models for inputs
watch(inputs, () => {
    if (inputs.value.length) {
        inputs.value.forEach(input => {
            structuredMessage.value.input[input.name] = input.inputType === 'Number' ? '0' : '';
        });
    } else {
        structuredMessage.value.input = {};
    }
});

watch(selectedReason, (newValue?: AutocompleteItem<MessageTemplate>) => {
    if (newValue) {
        structuredMessage.value = {
            ...structuredMessage.value,
            templateId: newValue.data.id,
            choice: {}
        };
        emit('updateStructuredMessage', structuredMessage.value);
        emit('updateReason', newValue.data);

        //reset subreason and push notifications
        selectedChoice.value = undefined;

        if (newValue.data.pushAvailable) {
            emit('updatePushNotifications', false);
        }
    }
});

watch(selectedChoice, (newValue?: AutocompleteItem<MessageChoiceTemplate>) => {
    if (newValue) {
        structuredMessage.value.choice = { [newValue.data.group]: newValue.data.name };
        emit('updateStructuredMessage', structuredMessage.value);
    }
});

watch(radioChoice, (newValue?: MessageChoiceTemplate) => {
    if (newValue) {
        structuredMessage.value.choice[newValue.group] = newValue.name;
        emit('updateStructuredMessage', structuredMessage.value);
    }
});

watch(pushNotifications, (newValue: boolean) => {
    emit('updatePushNotifications', newValue);
});

watch(reasonText, (newValue: string) => {
    emit('updateReasonText', newValue);
});

function increaseInput(input: string) {
    const result = Number(structuredMessage.value.input[input]) + 1;
    structuredMessage.value.input[input] = result.toString();
}

function decreaseInput(input: string) {
    const result = Number(structuredMessage.value.input[input]) - 1;
    if (result < 0) return;
    structuredMessage.value.input[input] = result.toString();
}

function toAutocompleteItem(messageTemplate: MessageTemplate) {
    return { title: messageTemplate.label, value: messageTemplate.id, data: messageTemplate };
}

function toChoiceAutocompleteItem(choiceTemplate) {
    return { title: choiceTemplate.label, value: choiceTemplate.name, data: choiceTemplate };
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';
.summary-container {
    padding-top: 16px;

    .editor {
        margin-bottom: 16px;
    }

    .push-notifications {
        margin-left: -10px;

        :deep(.v-label) {
            opacity: 1;
        }
    }

    .input-fields {
        .label {
            font-size: 12px;
            font-weight: 500;
        }

        .field {
            height: 44px;
            margin-bottom: 16px;

            :deep(.input-up),
            :deep(.input-down) {
                position: absolute;
                right: 3px;
                cursor: pointer;
            }

            :deep(.input-up) {
                top: 2px;
            }

            :deep(.input-down) {
                bottom: 2px;
            }

            // hiding default number arrows
            /* Chrome, Safari, Edge, Opera */
            :deep(input::-webkit-outer-spin-button),
            :deep(input::-webkit-inner-spin-button) {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            :deep(input[type='number']) {
                -moz-appearance: textfield;
            }
        }
    }

    .coice-radio,
    .choice-option {
        opacity: 1;

        :deep(.v-label),
        :deep(.v-selection-control__input > .v-icon) {
            opacity: 1;
        }
    }
}
</style>

<style lang="scss">
@import '@/shared/variables.scss';

.tox {
    .tox-toolbar-overlord {
        background-color: $lightGrey !important;
    }

    .tox-statusbar {
        background-color: $lightGrey !important;
    }

    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
        background-color: $lightGrey !important;
    }
}
</style>
